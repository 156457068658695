const styles = theme => ({
  wrapper: {
    // marginBottom: theme.spacing(6),
    // marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
});

export default styles;
